<template>
  <div class="profile">
    <div class="header">
      <img
        src="https://lh3.googleusercontent.com/a/ACg8ocK5m8Z3K_G7apbeKeRPBRuJuo-68dp4TJyGag66Wn5Rg90p1WJciA=s288-c-no"
        alt="Foto de perfil de Robert Almeida"
        class="profile-image"
      />
      <h1>{{ profile.name }}</h1>
      <p>
        <strong>{{ profile.position }}</strong>
      </p>
    </div>
    <div class="contact">
      <p><strong>Contato:</strong> {{ profile.email }} | {{ profile.phone }}</p>
      <p>
        <strong>GitHub:</strong>
        <a :href="profile.github" target="_blank">{{ profile.github }}</a>
      </p>
      <p><strong>Localização:</strong> {{ profile.location }}</p>
      <p><strong>Trabalho Remoto:</strong> {{ profile.remote }}</p>
    </div>
    <div class="section">
      <h2>Habilidades</h2>
      <ul class="skills">
        <li v-for="skill in profile.skills" :key="skill">{{ skill }}</li>
      </ul>
    </div>
    <div class="section">
      <h2>Formação</h2>
      <ul>
        <li v-for="cert in profile.certifications" :key="cert">
          Cursando Ciência de Dados (Graduação)
        </li>
      </ul>
    </div>
    <div class="section">
      <h2>Certificações</h2>
      <ul>
        <li v-for="cert in profile.certifications" :key="cert">
          <a href="cert.pdf" target="_blank">{{ cert }}</a>
        </li>
      </ul>
    </div>
    <div class="section">
      <h2>Status de Busca</h2>
      <p>{{ profile.status }}</p>
      <p><strong>Salário Mínimo:</strong> {{ profile.salary }}</p>
      <p><strong>Tipo de Contrato:</strong> {{ profile.contract }}</p>
      <p><strong>Nível de Inglês:</strong> {{ profile.english }}</p>
    </div>
    <div class="section">
      <h2>Experiências Profissionais</h2>
      <ul>
        <li v-for="experience in profile.experiences" :key="experience.company">
          <strong>{{ experience.position }} na {{ experience.company }}</strong>
          ({{ experience.period }})
          <p>{{ experience.description }}</p>
          <p><strong>Habilidades usadas:</strong> {{ experience.skills }}</p>
        </li>
      </ul>
    </div>
    <div class="section">
      <h2>Projetos</h2>
      <ul>
        <li v-for="project in profile.projects" :key="project.name">
          <strong
            ><a :href="project.link" target="_blank">{{
              project.name
            }}</a></strong
          >
          <p>{{ project.description }}</p>
        </li>
      </ul>
    </div>
    <div class="section">
      <h2>Um Pouco Mais Sobre Robert</h2>
      <p>{{ profile.about }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profile: {
        name: "Robert Rocha Soares de Almeida",
        position: "Desenvolvedor | Back-End",
        email: "robert.almeida1597@gmail.com",
        phone: "11995540438",
        github: "https://github.com/RobertAlmeida",
        location: "ITU",
        remote: "Sim",
        skills: [
          "TypeScript 3 - 4 anos ",
          "Git 3 - 4 anos ",
          "JavaScript 3 - 4 anos ",
          "Node.js 3 - 4 anos ",
          "AWS S3 2 - 3 anos ",
          "MySQL 2 - 3 anos ",
          "Express 2 - 3 anos ",
          "Linux 2 - 3 anos ",
          "TDD 2 - 3 anos ",
          "PHP 2 - 3 anos ",
          "API 2 - 3 anos ",
          "AWS RDS(Relational Database Service) 1 - 2 anos ",
          "AWS EC2(Elastic Compute Cloud) 1 - 2 anos ",
          "Python 1 - 2 anos ",
          "S.O.L.I.D 1 - 2 anos ",
          "Docker 1 - 2 anos ",
          "Vue.js 1 - 2 anos ",
          "Go 0 - 1 anos",
        ],
        certifications: ["English CEFR: A2"],
        status: "",
        salary: "Entre R$7.000 e R$8.000",
        contract: "PJ ou CLT",
        english: "Básico a intermediário",
        experiences: [
          {
            position: "Junior",
            company: "Senior Sistemas (Grande empresa)",
            period: "Jul 2019 - Sep 2021",
            description:
              "Geração de Builds, Controle de versão utilizando Git, Jira, entre outras atribuições de Dev&Ops.",
            skills: "Git, JavaScript, Node.js, GraphQL",
          },
        ],
        projects: [
          {
            name: "Flyn",
            link: "https://www.flyn.one/#/",
            description: "Plataforma para gerenciamento de urls.",
          },
          {
            name: "Qual Cardápio",
            link: "https://www.qualcardapio.com.br/",
            description: "Aplicativo para cardápios de restaurantes.",
          },

          {
            name: "Gerador de CPF ou CNPJ - Ferramenta para Desenvolvimento",
            link: "https://www.gerarcpfcnpj.com.br/",
            description:
              "Ferramenta para ajudar a testar formulários gerando CPF ou CNPJ",
          },
        ],
        about: `Olá! Se você está procurando um desenvolvedor back-end, deixe-me apresentar-me. Atualmente, estou cursando Ciência de Dados, o que me permite aplicar análises avançadas e habilidades de processamento de dados em soluções tecnológicas. Tenho experiência em linguagens de programação como Node.js, PHP e TypeScript, além de familiaridade com frameworks populares como Vue.js, React e Angular.

Meu foco principal é a construção de soluções de back-end escaláveis e robustas para aplicativos da web e móveis. Isso inclui a criação de APIs RESTful, gerenciamento de bancos de dados, integração de sistemas e implementação de segurança e autenticação. Também tenho conhecimento em manipulação e análise de dados, o que pode agregar valor ao desenvolvimento de soluções baseadas em dados.

Sou capaz de trabalhar em colaboração com equipes de front-end e designers UX/UI para garantir a melhor experiência para o usuário final. Além disso, estou comprometido em seguir as melhores práticas de codificação e testes automatizados para assegurar a qualidade do meu trabalho.

Se você está procurando um desenvolvedor back-end com uma visão orientada por dados e que possa ajudá-lo a desenvolver soluções escaláveis, seguras e eficientes para sua aplicação da web ou móvel, entre em contato comigo para discutir seus requisitos. Estou sempre pronto para ajudá-lo a levar sua ideia para o próximo nível.`,
      },
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

.profile {
  max-width: 900px;
  margin: 40px auto;
  padding: 30px;
  font-family: "Open Sans", sans-serif;
  background: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.header h1 {
  margin: 0;
  font-size: 2.8em;
  color: #343a40;
}

.header p {
  margin: 0;
  font-size: 1.5em;
  color: #495057;
}

.contact p {
  margin: 10px 0;
}

.section {
  margin-bottom: 30px;
}

.section h2 {
  font-size: 2em;
  color: #343a40;
  border-bottom: 3px solid #007bff;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.skills {
  display: flex;
  flex-wrap: wrap;
}

.skills li {
  background: #007bff;
  color: #ffffff;
  margin: 5px;
  padding: 10px 20px;
  border-radius: 50px;
  list-style: none;
}

.profile a {
  color: #007bff;
  text-decoration: none;
}

.profile a:hover {
  text-decoration: underline;
}

.contact,
.section {
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #e9ecef;
}

.section p {
  line-height: 1.6;
  color: #495057;
}

.profile-image {
  width: 150px; /* Defina o tamanho desejado para a imagem */
  height: 150px;
  border-radius: 50%; /* Para criar um efeito de borda arredondada */
  margin-bottom: 20px;
}
</style>
